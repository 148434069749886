import { useMemo } from "react";
import { Map, fromJS } from "immutable";

// Does a .toJS() if the passed in value has that method
// TODO use isImmutable from immutable.js when version >= 4.0.0
export const safeFromJS = (obj) => (Map.isMap(obj) ? obj : fromJS(obj));
export const safeToJS = (imOrJS) => (imOrJS?.toJS ? imOrJS.toJS() : imOrJS);

export const useSafeFromJS = (obj) => useMemo(() => safeFromJS(obj), [obj]);
const useSafeToJS = (imOrJS) => useMemo(() => safeToJS(imOrJS), [imOrJS]);

export default useSafeToJS;
