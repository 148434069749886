import combineImmutableReducers from "app/utils/redux/combineImmutableReducers";
import projectsReducer from "./reducers/projectsReducer";
import worksReducer from "./reducers/worksReducer";
import workResourcePreferencesReducer from "app/features/workResourcePreference/reducer";
import worksSameResourcesReducer from "./reducers/worksSameResourcesReducer";
import workImportancesReducer from "./reducers/workImportancesReducer";
import assignmentsReducer from "./reducers/assignmentsReducer";

// Params:
//    state: ImmutableJS record
const workReducer = combineImmutableReducers({
  assignments: assignmentsReducer,
  projects: projectsReducer,
  works: worksReducer,
  workImportances: workImportancesReducer,
  workResourcePreferences: workResourcePreferencesReducer,
  worksSameResources: worksSameResourcesReducer,
});

export default workReducer;
